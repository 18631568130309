import MainNavBar from '../components/MainNav';
import MainFooter from '../components/MainFooter';
import React,{useState,useEffect} from "react"
import BGImage from "../assets/images/headermain.jpg"
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import {  primaryColor, whiteColor } from '../assets/contants';
import TestImage from "../assets/images/headermain.jpg"
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Header from '../components/header';
import Aos from "aos"
import 'aos/dist/aos.css';
import ISO1 from "../assets/images/certificates/certi1.png"
import ISO2 from "../assets/images/certificates/certi2.png"
import ISO3 from "../assets/images/certificates/certi3.png"
import ISO4 from "../assets/images/certificates/certi4.png"
import ISO5 from "../assets/images/certificates/certi5.png"
import ISO6 from "../assets/images/certificates/certi6.png"
import ISO7 from "../assets/images/certificates/certi7.png"
import ISO8 from "../assets/images/certificates/certi8.png"


import About1 from "../assets/images/about1.jpg"
import About2 from "../assets/images/about2.jpg"




const useStyles = makeStyles((theme) => ({
  root:{
    flex:1,
    [theme.breakpoints.down('md')]: {
        overflowX:'hidden',
    },
  },
  headermain:{
    height:'300px',
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:400,
  },
  },
  header:{
    height:500,
    backgroundImage: `url(${TestImage})`,
    minHeight: 300,
    backgroundAttachment:'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    top:0,
    position:'absolute',
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:480,
  },
  },
  aboutustitle:{
      paddingTop:120,
      textAlign:'center',
      fontSize:50,
      color:whiteColor,
      fontWeight:'800'
  },
  aboutussubtitle:{
    textAlign:'center',
    fontSize:12,
    color:whiteColor
  },

  container:{
      // padding:'5% 10%'
  },
  title:{
    fontSize:20,
  },
  desc:{
      fontSize:16,
      color:'#777',
      margin:'20px 0px',
      textAlign:'justify'
  },
  cardcontainer:{
      padding:'50px 20px',
      backgroundColor:'#eee',
      [theme.breakpoints.down('md')]: {
        padding:'10px 10px',
        width:'100%'
    },
  },
  cardheader:{
      display:'flex',
      flexDirection:'row'
  },
  cardicon:{
      fontSize:50,
      color:primaryColor
  },
  cardtitle:{
      fontSize:20,
      padding:'5px 20px'
  },
  carddesc:{
    padding:'5px 20px',
    fontSize:16,
    color:'#777'
  },
  img:{
      width:'100%',
      height:300
  },
  imgiso:{
    width:'100%',
    // height:300
}
 
}));


function Aboutus() {
  const classes = useStyles();
  let listener = null
  const [scrollState, setScrollState] = useState(false)

  useEffect(() => {
    Aos.init({duration:2000})
    listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 10) {
          setScrollState(true)
      } else {
        setScrollState(false)
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [])



  return (
    <div className={classes.root}>
        <MainNavBar scrolled={scrollState}/>

        <Header name="About us" />

        <Container className={classes.container}>

            <Typography className={classes.title}  >About us</Typography>
            <Typography className={classes.desc}  >S Y E D Contracting has established itself as one of the leading and most successful design build and EPC for utilities infrastructure projects in the Middle East market, working for prestigious clients in recent years.Today, S Y E D Contracting is synonymous of flawless execution of unique and challenging projects.
            The “present team” has over 25 years of experience in Infrastructure works activities.
            Our firm employs an experienced and qualified team of engineers, including technical staff, senior project managers, qualified engineers, quantity surveyors, and many multi-skilled workers, as well as adequate labour and we own a fleet of construction equipment's.
            The company regularly audits its Systems and Operating Procedures to ensure that all work performed, or materials supplied by its approved suppliers, are fully compliant with the most recent legislation, satisfying the authority regulations while meeting the client's needs.

            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <div className={classes.cardcontainer}>
                    <div className={classes.cardheader}>
                        <ChatBubbleOutlineIcon className={classes.cardicon}/>
                        <div>
                        <Typography className={classes.cardtitle}  >Our Vision</Typography>
                        <Typography className={classes.carddesc}  >To be a market leader in the GCC construction industry. While achieving excellence in quality and timely completion of value-added projects, we aim to become the clients' preferred choice.</Typography>
                        </div>
                    </div>
                    </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div className={classes.cardcontainer}>
                    <div className={classes.cardheader}>
                        <QueryBuilderIcon className={classes.cardicon}/>
                        <div>
                        <Typography className={classes.cardtitle}  >Our Mission</Typography>
                        <Typography className={classes.carddesc}  >To provide the highest level of construction services to our clients at the most competitive prices. Our goal has remained the same since incorporation: to deliver high-quality projects on time.</Typography>
                        </div>
                    </div>
                    </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                {/* <div class="item-zoom">
                    <img src={About1} alt="img" className={classes.img}/>
                </div> */}
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                <Typography className={classes.title}>History</Typography>
                <Typography className={classes.desc}>S Y E D Contracting LLC has been established by Mr. Abdulla Khaleel Al Bannai for long term development strategy that is full of visionary aspirations, attainable goals and prolific promise</Typography>

                <div class="item-zoom">
                    <img src={About2} alt="img" className={classes.img}/>
                </div>
                </Grid> */}
            </Grid>
            <Typography className={classes.title}  >Certifications</Typography>

            <Grid container spacing={3}  >
                <Grid item xs={12} sm={4}>
                <div class="item-zoom">
                    <img src={ISO1} alt="img" className={classes.imgiso}/>
                </div>
                </Grid>

                <Grid item xs={12} sm={4}>
                <div class="item-zoom">
                    <img src={ISO2} alt="img" className={classes.imgiso}/>
                </div>
                </Grid>

                <Grid item xs={12} sm={4}>
                <div class="item-zoom">
                    <img src={ISO3} alt="img" className={classes.imgiso}/>
                </div>
                </Grid>

                <Grid item xs={12} sm={4}>
                <div class="item-zoom">
                    <img src={ISO4} alt="img" className={classes.imgiso}/>
                </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                <div class="item-zoom">
                    <img src={ISO5} alt="img" className={classes.imgiso}/>
                </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                <div class="item-zoom">
                    <img src={ISO6} alt="img" className={classes.imgiso}/>
                </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                <div class="item-zoom">
                    <img src={ISO7} alt="img" className={classes.imgiso}/>
                </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                <div class="item-zoom">
                    <img src={ISO8} alt="img" className={classes.imgiso}/>
                </div>
                </Grid>

̦̦̦̦̦̦̦
            </Grid>
        </Container>
       
      

        <MainFooter />
    </div>
  );
}



export default Aboutus;
