import React, {useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {firestore} from '../../firebase'
import {useHistory} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import { primaryColor } from '../../assets/contants';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © Syed Constructions Designed by '}
            <Link color="inherit" href="https://kraneapps.com/">
                KraneApps.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: primaryColor,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const classes = useStyles();
    const [username, setUsername] = React.useState('8200608175');
    const [password, setPassword] = React.useState('8200608175');
    const [isVisible, setIsVisible] = React.useState('');
    const history = useHistory();

    useEffect(() => {
        firestore.collection('admin').doc('login').get().then(snapshot => {
            const currentUser = localStorage.getItem('xpertusername');
            if(currentUser == snapshot.data().username ){
                history.push('/dashboard');
            }else{
            }
        })    },[])

    const handleLogin = () => {
        firestore.collection('admin').doc('login').get().then(snapshot => {
            console.log('login ==',snapshot.data().username)
            if(username.trim() == snapshot.data().username && password.trim() == snapshot.data().password){
                console.log('login success');
                localStorage.setItem('xpertusername', username);
                history.push('/dashboard');
            }else{
                console.log('login failed');
                setIsVisible(true)
                setTimeout(() =>setIsVisible(false), 1500 )
            }
        })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={(event) => setUsername(event.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => handleLogin()}
                    >
                        Sign In
                    </Button>
                    {/*<Grid container>*/}
                    {/*    <Grid item xs>*/}
                    {/*        <Link href="#" variant="body2">*/}
                    {/*            Forgot password?*/}
                    {/*        </Link>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item>*/}
                    {/*        <Link href="#" variant="body2">*/}
                    {/*            {"Don't have an account? Sign Up"}*/}
                    {/*        </Link>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </form>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' } }
                open={isVisible}
                onClose={() => setIsVisible(false)}
                message="Access Denied"
                // key={vertical + horizontal}
            />
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}
