import MainNavBar from '../../components/MainNav';
import MainFooter from '../../components/MainFooter';
import React,{useState,useEffect} from "react"
import BGImage from "../../assets/images/service3.jpeg"
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import {  blackColor, primaryColor, whiteColor } from '../../assets/contants';
import TestImage from "../../assets/images/headermain.jpg"
import Header from '../../components/header';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme) => ({
  root:{
    flex:1,
    [theme.breakpoints.down('md')]: {
        overflowX:'hidden',
    },
  },
  headermain:{
    height:400,
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:400,
  },
  },
  header:{
    height:500,
    backgroundImage: `url(${TestImage})`,
    minHeight: 300,
    backgroundAttachment:'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    top:0,
    position:'absolute',
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:500,
  },
  },
  aboutustitle:{
      paddingTop:120,
      textAlign:'center',
      fontSize:50,
      color:whiteColor
  },
  aboutussubtitle:{
    textAlign:'center',
    fontSize:12,
    color:whiteColor
  },
  img:{
      width:'95%',
      height:200,
      objectFit:'cover',
      transition : "transform .4s ease",
      "&:hover":{
        transform: 'scale(1.1)',
        transformOrigin: '50% 50%',
        
      }
  },
  broucherbutton:{
    display:'flex',
    flexDirection:'row',
    backgroundColor:blackColor,
    justifyContent:'center',
    padding:'8px 20px',
    margin:'0% 10%',
    borderRadius:4,
    fontWeight:'400'

  },
  icon:{
    color:whiteColor,
    marginRight:10,
    fontSize:22
  },
  brouchertitle:{
    color:whiteColor,
    fontWeight:'600',
    letterSpacing:1,
    fontSize:16
  },
  description:{
    fontSize:16,
    color:'#777',
    margin:'20px 5px'
  },
  list:{
      fontSize:16,
      color:'#777',
  },
  image:{
    width:'100%',
    height:300,
    objectFit:'contain'
  }
 
}));


function BackFilling() {
  const classes = useStyles();
  let listener = null
  const [scrollState, setScrollState] = useState(false)

  useEffect(() => {
    listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      console.log(scrolled)
      if (scrolled >= 10) {
          setScrollState(true)
      } else {
        setScrollState(false)
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [])



  return (
    <div className={classes.root}>
        <MainNavBar scrolled={scrollState}/>
        <Header name="Backfilling" />

        <Container className={classes.container}>
            <Grid container>
              <Grid item xs={12} sm={7}>
                  <Typography className={classes.description}>
                  Backfilling is the process of reintroducing soil into a trench or foundation after the excavation and related work has been completed.                   </Typography>
                  <Typography className={classes.description}>
                  Backfilling requires skills, heavy equipment, and knowledge of specifications, contract requirements, and soil conditions. 
                  </Typography>
                  <Typography className={classes.description}>
                  Each soil type has its own set of characteristics, necessitating a different set of construction techniques to ensure optimal performance. 
                  </Typography>
                  <Typography className={classes.description}>
                  When compacting backfill, backfill crews must take care to avoid impact loading any pipeline, shaft, structure, cabling, or other buried elements. 
                 </Typography>
                 <Typography className={classes.description}>
                 Backfilling and compacting backfill can be accomplished in a variety of ways. 
                 </Typography>
                 <Typography className={classes.description}>
                 Filling and compacting utility trenches necessitates special considerations.
                 </Typography>

              </Grid>
              <Grid item xs={12} sm={5}>
              <img src={BGImage} className={classes.image} />
                </Grid>
            </Grid>
        </Container>

        <MainFooter />
    </div>
  );
}



export default BackFilling;
