import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
// import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import Title from './Title';
import {useEffect} from "react";
import { primaryColor } from '../../assets/contants';
import {firestore} from '../../firebase'
import Typography from "@material-ui/core/Typography";

// Generate Sales Data
function createData(time, amount) {
  return { time, amount };
}

const data = [
  createData('00:00', 0),
  createData('03:00', 300),
  createData('06:00', 600),
  createData('09:00', 800),
  createData('12:00', 1500),
  createData('15:00', 2000),
  createData('18:00', 2400),
  createData('21:00', 2400),
  createData('24:00', undefined),
];

export default function Chart() {
  const theme = useTheme();
    const [leadsLength, setLeadsLength] = React.useState(0);

    useEffect(() => {
        firestore.collection("leads").get().then(snapshot => {
            var leads = [];
            // snapshot.docs.map(doc => {
            //     leads.push(createData(doc.data().type, doc.data().name, doc.data().phone, doc.data().email, doc.data().city, doc.data().timestamp))
            // })
            setLeadsLength(snapshot.docs.length)
            // setAllLeads(leads)
            console.log('lenght ==',snapshot.docs.length)
        })
    },[])

    return (
        <React.Fragment>
            <Title>Total Leads</Title>
            <Typography component="p" variant="h4">
                {leadsLength}
            </Typography>
            <Typography color="textSecondary" sx={{ flex: 1 }}>
                as on {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}
            </Typography>
            {/*<div>*/}
            {/*  <Link color="primary" href="#" onClick={preventDefault}>*/}
            {/*    View balance*/}
            {/*  </Link>*/}
            {/*</div>*/}
        </React.Fragment>
    );
}
