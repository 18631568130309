import MainNavBar from '../components/MainNav';
import MainFooter from '../components/MainFooter';
import React,{useState,useEffect} from "react"
import BGImage from "../assets/images/headermain.jpg"
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import {  primaryColor, whiteColor } from '../assets/contants';
import TestImage from "../assets/images/headermain.jpg"
import Header from '../components/header';
import { firestore } from '../firebase';
import Gallery from 'react-grid-gallery';

import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const useStyles = makeStyles((theme) => ({
  root:{
    flex:1,
    [theme.breakpoints.down('md')]: {
        overflowX:'hidden',
    },
  },
  headermain:{
    height:400,
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:400,
  },
  },
  header:{
    height:500,
    backgroundImage: `url(${TestImage})`,
    minHeight: 300,
    backgroundAttachment:'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    top:0,
    position:'absolute',
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:500,
  },
  },
  aboutustitle:{
      paddingTop:120,
      textAlign:'center',
      fontSize:50,
      color:whiteColor
  },
  aboutussubtitle:{
    textAlign:'center',
    fontSize:12,
    color:whiteColor
  },
  image:{
      height:200,
      width:'100%',
      objectFit:'contain'
  },
  cardroot:{
    width:'100%'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  BgImage:{
    width:'100%',
    height:200
  },
  title:{
    fontSize: 23,
    fontWeight:'600',
    padding:"20px 0px"
  },
  description:{
    fontSize: 16,
    fontWeight:'500',
    width:'80%',
    // padding:"10px 0px"
  }

}));


function Projects() {
  const classes = useStyles();
  let listener = null
  const [scrollState, setScrollState] = useState(false)
  const [expanded, setExpanded] = React.useState(false);
  const [testArray, setTestArray] = React.useState([]);
  const [allprojects, setAllprojects] = React.useState([]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  useEffect(() => {
    getAllProducts()
    listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      console.log(scrolled)
      if (scrolled >= 10) {
          setScrollState(true)
      } else {
        setScrollState(false)
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [])

  const getAllProducts = () =>{
    firestore.collection('projects').get().then(snapshot => {
        const list = snapshot.docs.map(doc =>  {
            return doc.data()
        });
        // setCards(list)
        console.log(list)
        list.forEach((project) => {
          let dummyarray = []
          project.images.forEach((item) => {
            dummyarray.push({
              src: item,
              thumbnail: item,
              thumbnailWidth: 800,
              thumbnailHeight: 500,
            })
          })
          console.log('formatted', dummyarray)
          project.images = dummyarray
        })
        setAllprojects(list)
    })
}


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  return (
    <div className={classes.root}>
        <MainNavBar scrolled={scrollState}/>
        <Header name="Projects" />
        <div style={{height:'100%'}}>
          {allprojects.map(project => {
            let points = project.description.split(',')
            return(
              <Container style={{overflowX:'hidden'}}>
              <Typography className={classes.title}>{project.title}</Typography>
              <ul>
              {points.map(item => {
                return(
                  <li><Typography  className={classes.description}>{item}</Typography></li>
                )
              })}
              </ul>
              <Gallery margin={4} style={{height:'100%', objectFit:'cover'}} images={project.images}/><br/><br/>
              </Container>
            )
          })}           
        </div>
        <MainFooter />
    </div>
  );
}



export default Projects;
