import MainNavBar from '../components/MainNav';
import MainFooter from '../components/MainFooter';
import React,{useState,useEffect} from "react"
import BGImage from "../assets/images/headermain.jpg"
import Home1 from "../assets/images/home1.jpg"
import Home2 from "../assets/images/home2.jpg"
import Home3 from "../assets/images/home3.jpg"
import HomeAbout from "../assets/images/homeabout.jpg"
import Aos from "aos"
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import 'aos/dist/aos.css';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import { blackColor, primaryColor, whiteColor } from '../assets/contants';
import TestImage from "../assets/images/headermain.jpg"
import CheckIcon from '@material-ui/icons/Check';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Logo from '../assets/images/logo_light.png'
import Client1 from "../assets/images/clientlogos/client1.jpg"
import Client2 from "../assets/images/clientlogos/client2.jpg"
import Client3 from "../assets/images/clientlogos/client3.jpg"
import Client4 from "../assets/images/clientlogos/client4.jpg"
import Client5 from "../assets/images/clientlogos/client5.jpg"
import Client6 from "../assets/images/clientlogos/client6.jpg"
import Client7 from "../assets/images/clientlogos/client7.jpg"
import Client8 from "../assets/images/clientlogos/client8.jpg"
import Client9 from "../assets/images/clientlogos/client9.jpg"
import Client10 from "../assets/images/clientlogos/client10.jpg"
import Client11 from "../assets/images/clientlogos/client11.jpg"
import Client12 from "../assets/images/clientlogos/client12.jpg"
import Client13 from "../assets/images/clientlogos/client13.jpg"
import Client14 from "../assets/images/clientlogos/client14.jpg"
import Client15 from "../assets/images/clientlogos/client15.jpg"



const useStyles = makeStyles((theme) => ({
  root:{
    flex:1,
    [theme.breakpoints.down('md')]: {
        overflowX:'hidden',
    },
  },
  heademain:{
    height:'450px',
    width:'100%',
    fontFamily:'Open Sans',
    [theme.breakpoints.down('md')]: {
      height:400,
  },
  },
  header:{
    backgroundImage: `url(${TestImage})`,
    minHeight: '100vh',
    backgroundAttachment:'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    top:0,
    fontFamily:'Open Sans',
    position:'absolute',
    width:'100%',
    // opacity:0.4,
    // backgroundColor:blackColor,
    backgroundColor: 'linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5))',
    [theme.breakpoints.down('md')]: {
      height:500,

  },
  },
 bgtitlediv:{
  width:'100%',
  paddingTop:180,
  textShadowColor: '#000000',
  textShadowOffset: {width: 1, height: 1},
  textShadowRadius: 10,
  fontFamily:'Open Sans',
  [theme.breakpoints.down('md')]: {
    paddingTop:120,
},
 },
 bgtitle:{
   fontSize:40,
   color: whiteColor,
   textAlign:'center',
   margin:'0 20% ',
   marginTop:'10%',
   fontFamily:'Open Sans',
   [theme.breakpoints.down('md')]: {
    fontSize:30,
  },
   
 },
 subtitle:{
  fontSize:22,
  color: primaryColor,
  textAlign:'center',
  fontWeight:'800',
  marginTop:30,
  fontFamily:'Open Sans',
  transition: 'opacity 1s ease-in',
  [theme.breakpoints.down('md')]: {
    fontSize:16,
},
 },
 hireuscontainer:{
   padding:"15px 30px",
   display:'flex',
   justifyContent:'space-between',
   backgroundColor:primaryColor,
   marginTop:20,
   fontFamily:'Open Sans',
   [theme.breakpoints.down('md')]: {
    padding:"5px 10px",
},

 },
 hiretitle:{
   padding:'15px',
   color:whiteColor,
   fontSize:20,
   fontFamily:'Open Sans',
   [theme.breakpoints.down('md')]: {
    padding:'15px',
    width:'50%',
    fontSize:12
},
 },
 hirebutton:{
  border:'2px solid white',
  padding:'8px 50px',
  marginTop:10,
  color:whiteColor,
  cursor:'pointer',
  fontFamily:'Open Sans',
  backgroundPosition: 'right bottom',
  "&:hover":{
    background:'white',
    color: blackColor,
    border:'2px solid black',
    transition:'transform 250ms ease',
    color:whiteColor,
    background: "linear-gradient(to right, black 50%, transparent 50%)",
   backgroundSize: '200% 100%',
   backgroundPosition: 'left bottom',
   transition: "all .5s ease-out",
  },
  [theme.breakpoints.down('md')]: {
    padding:'5px 20px',
    textAlign:'center',
    fontSize:12,
    marginTop:"15%"
},
},

 visioncard:{
  height:'300px',
  backgroundColor:'#ddd',
  marginTop:'15%',
  fontFamily:'Open Sans',
  zIndex:1,
  [theme.breakpoints.down('md')]: {
    height:'600px',
},
 },
 visioncard2:{
  height:'300px',
  backgroundColor:'#ddd',
  marginTop:'15%',
  fontFamily:'Open Sans',
  zIndex:1,
  [theme.breakpoints.down('md')]: {
    display:'none',
},
 },
 visionimages:{
   objectFit:'cover',
   height:'300px',
   width:'100%',
   fontFamily:'Open Sans',
   [theme.breakpoints.down('md')]: {
    display:'none',
},
 },
 visioncardinside:{
   padding:'40px',
   display:'flex',
   flexDirection:'column',
   fontFamily:'Open Sans',
   alignItems:'start'
 },
 visiontitle:{
   fontSize:25,
   fontFamily:'Open Sans',
   fontWeight:500,
   letterSpacing:1
 },
 visionunderline:{
   padding:1,
   backgroundColor:primaryColor,
   width:'20%',
   borderRadius:2,
   margin:'20px 0px',
   fontFamily:'Open Sans',
 },
 visiondesc:{
   fontSize:16,
   fontFamily:'Open Sans',
   color:'#777',
   width:'100%',
   textAlign:'justify',
   overflowX:'hidden',
 },
 goaltitle:{
   margin:'20px 25%',
   fontSize:35,
   textAlign:'center',
   letterSpacing:1,
   fontWeight:500,
   fontFamily:'Open Sans',
   [theme.breakpoints.down('md')]: {
    margin:'20px 5%',
    fontSize:20,
},
 },
 aboutuscontainer:{
   padding:'10% 50px',
   backgroundColor:'#2b2b2b',
   [theme.breakpoints.down('md')]: {
    padding:'10% 20px',
  },
 },
 aboutustext:{
   fontSize:16,
   color:whiteColor,
   padding:'2%',
   paddingRight:"10%",
   textAlign:'justify',
   fontFamily:'Open Sans'
 },
 aboutusbuttoncontainer:{
   display:'flex',
   fontFamily:'Open Sans',
   justifyContent:'flex-end'
 },
 aboutusbutton:{
   color:whiteColor,
   padding:'6px 20px',
   border:'2px solid #fff',
   marginTop:30,
   marginRight:"%",
  fontWeight:'600',
  "&:hover":{
    backgroundColor:primaryColor,
   transition: "all .5s ease-out",
  },

 },
 aboutimage:{
   width:'100%',
   objectFit:'cover',
   fontFamily:'Open Sans',
   height:"100%"

 },
 recentworks:{
   margin:'2% 0px',
   fontFamily:'Open Sans',
 },
 recentimage:{
   height:200,
   width:'100%',
   fontFamily:'Open Sans',
   objectFit:'contain'
 },
 Whychooseuscontainer:{
  // backgroundImage: `url(${TestImage})`,
  minHeight: 500,
  backgroundColor:'rgb(34, 34, 34)' ,
  fontFamily:'Open Sans',
  backgroundAttachment:'fixed',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  padding:'5%'
 },
 chooseuscontainer:{
   display:'flex'
 },
 chooseuscontainertick:{
  margin:'0 30px',
  [theme.breakpoints.down('md')]: {
    margin:'0 0px',
  },
},
 choosetitle:{
  fontSize:20,
  paddingTop:10,
  color:whiteColor,
  fontFamily:'Open Sans',
 },
 choosedesc:{
   color:whiteColor,
   fontSize:12,
   paddingTop:10,
   fontFamily:'Open Sans',
   textAlign:'justify'
 },
 clientimage:{
   width:'100%',
   height:200,
   objectFit:'contain',
 }

}));


function Homepage() {
  const classes = useStyles();
  let listener = null
  const [scrollState, setScrollState] = useState(false)
  const [service, setService] = useState("Enabling & Earthmoving Services")


  useEffect(() => {
    setTimeout(() => { setService("Transmission Pipe Laying") }, 2000);
    setTimeout(() => {setService("Backfilling") }, 4000);
    setTimeout(() => { setService("Chamber Works") }, 6000);
    setTimeout(() => { setService("Design & Engineering") }, 8000);
    setTimeout(() => { setService("District Cooling Department  ") }, 10000);
    setTimeout(() => { setService("Micro-Tunnelling") }, 12000);
    setTimeout(() => { setService("Preventive Maintenance") }, 14000);

    Aos.init({duration:2000})
    listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 10) {
          setScrollState(true)
      } else {
        setScrollState(false)
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  


  return (
    <div className={classes.root}>
        <MainNavBar scrolled={scrollState}/>
        <div className={classes.heademain} >
        <div className={classes.header}>
          <div className={classes.bgtitlediv}>
          <Typography className={classes.bgtitle}   >
            We are commited to execute and deliver.
          </Typography>
          <TransitionGroup timeout={200} classNames="my-node">
          <Typography className={classes.subtitle} >{service}</Typography>
          </TransitionGroup>
          </div>
          </div>
        </div>
        {/* <div className={classes.hireuscontainer}>
            <Typography variant="h5" className={classes.hiretitle}>Looking for best partner for your next construction works?</Typography>
            <div>
            <Typography className={classes.hirebutton}>Contact Us</Typography>
            </div>
        </div> */}

        
        <Grid container>
          <Grid item xs={12} sm={4} className={classes.visioncard2}>
            <img src={Home1} alt="image" className={classes.visionimages} />
          </Grid>
          <Grid item xs={12} sm={8} className={classes.visioncard}>
              <div className={classes.visioncardinside}>
                  <Typography className={classes.visiontitle} >History</Typography>
                  <div className={classes.visionunderline}></div>
                  <Typography className={classes.visiondesc}  >S Y E D Contracting LLC has been established in 2018 for long term development strategy that is full of visionary aspirations, attainable goals, and prolific promise. We have successfully established strong relationship with DEWA, FEWA, EMPOWER, EMARAT, RTA, Dubai MUNCIPALITY, ENOC, China estate, L&T, Shapoorji Pallonji and many more. S Y E D Contracting has become the synonymous of flawless execution<br/>of unique and challenging projects. The “present team” has over 25 years of experience in Infrastructure works activities.</Typography>

              </div>
          </Grid>
          {/* <Grid item xs={12} sm={4}>
          <img src={Home2} alt="image" className={classes.visionimages} />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.visioncard}>
              <div className={classes.visioncardinside}>
                  <Typography className={classes.visiontitle}>Our Vision</Typography>
                  <div className={classes.visionunderline}></div>
                  <Typography className={classes.visiondesc}>To be marrket leader in the GCC Construction industry. While acheiving excellence in quality and timely completion of value-added projects,we aim to become the clients preffered choice.</Typography>
              </div>
          </Grid>
          <Grid item xs={12} sm={4}>
          <img src={Home3} alt="image" className={classes.visionimages} />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.visioncard}>
              <div className={classes.visioncardinside}>
                  <Typography className={classes.visiontitle}>Our Mission</Typography>
                  <div className={classes.visionunderline}></div>
                  <Typography className={classes.visiondesc}>To provide the highest level of construction services to our clients at the most competitive prices. Our goal has remained the same since incorporation: to deliver high-quality projects on time. </Typography>
              </div>
          </Grid> */}
        </Grid>

        

        <Typography className={classes.goaltitle}   >Our goal then and now is to provide equality on time projects.</Typography>
       
       <Grid container>
         <Grid xs={12} sm={8}>
            <div className={classes.aboutuscontainer}  >
                  <Typography className={classes.visiontitle} style={{color:whiteColor}}  >About Us</Typography>
                  <div className={classes.visionunderline} style={{width:'10%'}}></div>
                  <Typography className={classes.aboutustext} >
                  S Y E D Contracting has established itself as one of the leading and most successful design build and EPC for utilities infrastructure projects in the Middle East market, working for prestigious clients in recent years.
                  </Typography>
                  <Typography className={classes.aboutustext}>
                  Today, S Y E D Contracting is synonymous of flawless execution of unique and challenging projects.
                  The “present team” has over 25 years of experience in Infrastructure works activities.
                  </Typography>
                  <Typography className={classes.aboutustext}>
                  Our firm employs an experienced and qualified team of engineers, including technical staff, senior project managers, qualified engineers, quantity surveyors, and many multi-skilled workers, as well as adequate labour and we own a fleet of construction equipment's.

                  </Typography>
                  <Typography className={classes.aboutustext}>
                  The company regularly audits its Systems and Operating Procedures to ensure that all work performed, or materials supplied by its approved suppliers, are fully compliant with the most recent legislation, satisfying the authority regulations while meeting the client's needs.
                  </Typography>
                  <div className={classes.aboutusbuttoncontainer}>
                      <a style={{cursor:'pointer'}} href="/aboutus"><Typography className={classes.aboutusbutton}>READ MORE</Typography></a>
                  </div>
            </div>
         </Grid>
         <Grid xs={12} sm={4}>
          <img src={HomeAbout} alt="image" className={classes.aboutimage} />
        </Grid>
       </Grid>

       {/* <Container>
         <div className={classes.recentworks}>
         <Typography className={classes.visiontitle} >Recent Works</Typography>
          <div className={classes.visionunderline} style={{width:'10%'}}></div>
         </div>
         
         <Grid container spacing={4}>
           <Grid item xs={12} sm={3}>
              <img src={BGImage} alt="image" className={classes.recentimage} />
          </Grid>
          <Grid item xs={12} sm={3}>
              <img src={BGImage} alt="image" className={classes.recentimage} />
          </Grid>
          <Grid item xs={12} sm={3}>
              <img src={BGImage} alt="image" className={classes.recentimage} />
          </Grid>
          <Grid item xs={12} sm={3}>
              <img src={BGImage} alt="image" className={classes.recentimage} />
          </Grid>
          <Grid item xs={12} sm={3}>
              <img src={BGImage} alt="image" className={classes.recentimage} />
          </Grid>
          <Grid item xs={12} sm={3}>
              <img src={BGImage} alt="image" className={classes.recentimage} />
          </Grid>
          <Grid item xs={12} sm={3}>
              <img src={BGImage} alt="image" className={classes.recentimage} />
          </Grid>
          <Grid item xs={12} sm={3}>
              <img src={BGImage} alt="image" className={classes.recentimage} />
          </Grid>
         </Grid>
       </Container> */}

       <div className={classes.Whychooseuscontainer}>
         <Typography style={{color:whiteColor}} className={classes.visiontitle}  >Why Choose Us</Typography>
          <div className={classes.visionunderline} style={{width:'10%'}}></div>
            <Container>
              <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.chooseuscontainer}>
                        <CheckIcon className={classes.chooseuscontainertick} style={{color:primaryColor,fontSize:50}}/>
                        <div>
                          <Typography className={classes.choosetitle}  >Experience</Typography>
                          <Typography className={classes.choosedesc}  >Has over 25 years of experience in the construction industry on a Design and Build, Engineering, Procurement, Construction, and Commissioning of Water Works for the Infrastructure activities in Dubai. That Provide us with social and structural resources for the team to achieve our common goal in every task. “Together we Accomplishes More.” Our employees, staff, sub-contractors work together for perfection accomplishment of difficult tasks.</Typography>
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.chooseuscontainer}>
                        <CheckIcon className={classes.chooseuscontainertick} style={{color:primaryColor,fontSize:50}}/>
                        <div>
                          <Typography className={classes.choosetitle}  >Safety</Typography>
                          <Typography className={classes.choosedesc}  >We have laid down clear guidelines for safety and precaution at all sites. All the staff under-go safety induction sessions at every site. We ensure our work areas are tidy throughout to reduce the number of slip and trip hazards. Pay particular attention to access and escape routes.  Hard hat, safety boots and hi-viz vest are a minimum requirement for work areas, along with any additional PPE required for the task being carried out. </Typography>
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.chooseuscontainer}>
                        <CheckIcon className={classes.chooseuscontainertick} style={{color:primaryColor,fontSize:50,}}/>
                        <div>
                          <Typography className={classes.choosetitle}  >Work on timeline</Typography>
                          <Typography className={classes.choosedesc}  >Our company has an enviable track record of timely project completion. Being upfront about project timelines, target dates, and scheduling aids in the development of a trusting relationship with our clients. By checking the critical path, setting reasonable activity durations and keeping date constraints to a minimum help us achieve the set duration of project on time.</Typography>
                        </div>
                    </div>
                  </Grid>
                  
                  <Grid item xs={12} sm={4}>
                    <div className={classes.chooseuscontainer}>
                        <CheckIcon className={classes.chooseuscontainertick} style={{color:primaryColor,fontSize:50,}}/>
                        <div>
                          <Typography className={classes.choosetitle}  >Cost effective</Typography>
                          <Typography className={classes.choosedesc}  >By creating competition between suppliers, then eliminates multiple sources and being efficient with our inventory management system reduces our cost and increase our efficiency without compromising the service quality.</Typography>
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.chooseuscontainer}>
                        <CheckIcon className={classes.chooseuscontainertick} style={{color:primaryColor,fontSize:50}}/>
                        <div>
                          <Typography className={classes.choosetitle}  >Can execute difficult project </Typography>
                          <Typography className={classes.choosedesc}  >With right set of skills, experience and talents. Our strategic approach that outlines the execution of task aid us to complete the project.</Typography>
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.chooseuscontainer}>
                        <CheckIcon className={classes.chooseuscontainertick} style={{color:primaryColor,fontSize:50,}}/>
                        <div>
                          <Typography className={classes.choosetitle}  >Problem solver</Typography>
                          <Typography className={classes.choosedesc}  >We determine the actual cause, assign a list of possible solutions and then execute all issues with respect to the environment situation ,action needed and  Always have a contingency plan to execute the project.</Typography>
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.chooseuscontainer}>
                        <CheckIcon className={classes.chooseuscontainertick} style={{color:primaryColor,fontSize:50}}/>
                        <div>
                          <Typography className={classes.choosetitle}  >Knowledge</Typography>
                          <Typography className={classes.choosedesc}  > Our Knowledge in Water Pipeline works goes all the way back to initial stage of water works in UAE.</Typography>
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.chooseuscontainer}>
                        <CheckIcon  className={classes.chooseuscontainertick} style={{color:primaryColor,fontSize:50}}/>
                        <div>
                          <Typography className={classes.choosetitle}  >Resources</Typography>
                          <Typography className={classes.choosedesc}>We have a fleet of machineries and equipment’s with high performance of productivity</Typography>
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.chooseuscontainer}>
                        <CheckIcon className={classes.chooseuscontainertick} style={{color:primaryColor,fontSize:50}}/>
                        <div>
                          <Typography className={classes.choosetitle}  >Devoted </Typography>
                          <Typography className={classes.choosedesc}  >We set a clear vision and project goals.</Typography>
                        </div>
                    </div>
                  </Grid>
              </Grid>
            </Container>
         </div> 


         <div style={{margin:'4% 10%'}}>
         <Typography className={classes.visiontitle} arrows={false}  >Our Clients & Consultants</Typography>
          <div className={classes.visionunderline} style={{width:'10%'}}></div>
         <Carousel responsive={responsive} arrows={false} infinite={true} autoPlay={true}  >
          <div>
            <img src={Client1} alt="logo" className={classes.clientimage} />
          </div>
           <div>
            <img src={Client2} alt="logo" className={classes.clientimage} />
          </div>
           <div>
            <img src={Client3} alt="logo" className={classes.clientimage} />
          </div>
           <div>
            <img src={Client4} alt="logo" className={classes.clientimage} />
          </div>
           <div>
            <img src={Client5} alt="logo" className={classes.clientimage} />
          </div>
           <div>
            <img src={Client6} alt="logo" className={classes.clientimage} />
          </div>
           <div>
            <img src={Client7} alt="logo" className={classes.clientimage} />
          </div>
           <div>
            <img src={Client8} alt="logo" className={classes.clientimage} />
          </div>
          <div>
            <img src={Client9} alt="logo" className={classes.clientimage} />
          </div>
          <div>
            <img src={Client10} alt="logo" className={classes.clientimage} />
          </div>
          <div>
            <img src={Client11} alt="logo" className={classes.clientimage} />
          </div>
          <div>
            <img src={Client12} alt="logo" className={classes.clientimage} />
          </div>
          <div>
            <img src={Client13} alt="logo" className={classes.clientimage} />
          </div>
          <div>
            <img src={Client14} alt="logo" className={classes.clientimage} />
          </div>
          <div>
            <img src={Client15} alt="logo" className={classes.clientimage} />
          </div>
        </Carousel>
         </div>

        <MainFooter />
    </div>
  );
}



export default Homepage;
