import Firebase from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBx-g5xVzeHAE6lxXGNWRqXP7ANiWzQOeY",
    authDomain: "syed-contracting.firebaseapp.com",
    projectId: "syed-contracting",
    storageBucket: "syed-contracting.appspot.com",
    messagingSenderId: "605275106530",
    appId: "1:605275106530:web:35f742d26c0a273fbe82bb"
};
  

// Initialize Firebase
const firebase = Firebase.initializeApp(firebaseConfig);
const database = firebase.database();
export {database};
const auth = firebase.auth();
export {auth}
const firestore = firebase.firestore();
export {firestore}
const storage = firebase.storage();
export {storage}
