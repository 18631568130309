import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TestImage from "../assets/images/headermain.jpg"
import { primaryColor, whiteColor } from '../assets/contants';

const useStyles = makeStyles((theme) => ({
    headermain:{
      height:'300px',
      width:'100%',
      [theme.breakpoints.down('md')]: {
        height:500,
    },
    },
    header:{
      height:400,
      backgroundImage: `url(${TestImage})`,
      minHeight: 300,
      backgroundAttachment:'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      top:0,
      position:'absolute',
      width:'100%',
      [theme.breakpoints.down('md')]: {
        height:500,
    },
    },
    aboutustitle:{
        paddingTop:200,
        textAlign:'center',
        fontSize:50,
        color:whiteColor
    },
    aboutussubtitle:{
      textAlign:'center',
      fontSize:12,
      color:whiteColor,
      fontStyle:'bold',
      fontWeight: 800
    },
}))

export default function Header(props) {
    const classes = useStyles()
    return (
        <div className={classes.headermain}>
            <div className={classes.header}>
                    <Typography className={classes.aboutustitle}>{props.name}</Typography>
                    {/* <Typography className={classes.aboutussubtitle}>S Y E D Contracting LLC > {props.name}</Typography> */}
            </div>
        </div>
    )
}
