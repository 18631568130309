import MainNavBar from '../../components/MainNav';
import MainFooter from '../../components/MainFooter';
import React,{useState,useEffect} from "react"
import BGImage from "../../assets/images/home3.jpg"
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import {  blackColor, primaryColor, whiteColor } from '../../assets/contants';
import TestImage from "../../assets/images/headermain.jpg"
import Header from '../../components/header';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme) => ({
  root:{
    flex:1,
    [theme.breakpoints.down('md')]: {
        overflowX:'hidden',
    },
  },
  headermain:{
    height:400,
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:400,
  },
  },
  header:{
    height:500,
    backgroundImage: `url(${TestImage})`,
    minHeight: 300,
    backgroundAttachment:'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    top:0,
    position:'absolute',
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:500,
  },
  },
  aboutustitle:{
      paddingTop:120,
      textAlign:'center',
      fontSize:50,
      color:whiteColor
  },
  aboutussubtitle:{
    textAlign:'center',
    fontSize:12,
    color:whiteColor
  },
  img:{
      width:'95%',
      height:200,
      objectFit:'cover',
      transition : "transform .4s ease",
      "&:hover":{
        transform: 'scale(1.1)',
        transformOrigin: '50% 50%',
        
      }
  },
  broucherbutton:{
    display:'flex',
    flexDirection:'row',
    backgroundColor:blackColor,
    justifyContent:'center',
    padding:'8px 20px',
    margin:'0% 10%',
    borderRadius:4,
    fontWeight:'400'

  },
  icon:{
    color:whiteColor,
    marginRight:10,
    fontSize:22
  },
  brouchertitle:{
    color:whiteColor,
    fontWeight:'600',
    letterSpacing:1,
    fontSize:16
  },
  description:{
    fontSize:16,
    color:'#777',
    margin:'20px 0px'
  },
  list:{
      fontSize:16,
      color:'#777',
  },
  image:{
    width:'100%',
    height:300,
    objectFit:'contain'
  }
 
}));


function Preventive() {
  const classes = useStyles();
  let listener = null
  const [scrollState, setScrollState] = useState(false)

  useEffect(() => {
    listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      console.log(scrolled)
      if (scrolled >= 10) {
          setScrollState(true)
      } else {
        setScrollState(false)
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [])



  return (
    <div className={classes.root}>
        <MainNavBar scrolled={scrollState}/>
        <Header name="Preventive Maintenance" />

        <Container className={classes.container}>
            <Grid container>
              <Grid item xs={12} sm={9}>

                  <Grid container spacing={3}>
                   <Grid item xs={6}>
                        <img src={BGImage} className={classes.image} />
                    </Grid> 
                    <Grid item xs={6}>
                        <Typography className={classes.description}>
                        Preventive maintenance (PM) is "a routine with the goal of "noticing small problems and fixing them before major ones develop. "                        </Typography>
                        <Typography className={classes.description}>
                        Maintenance activities include partial or complete overhauls at specified period, minor adjustments, and so on. In addition, workers can record equipment deterioration so they know to replace or repair worn parts before they cause system failure. The work carried out for portable drinking water pipe line to avoids its breakdown.
                        </Typography>
                    </Grid> 
                  </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
              {/* <div className={classes.broucherbutton}>
                  <DescriptionIcon className={classes.icon} />
                  <Typography className={classes.brouchertitle}>BROUCHER</Typography>
              </div> */}
                </Grid>
            </Grid>
        </Container>

        <MainFooter />
    </div>
  );
}



export default Preventive;
