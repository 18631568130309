import MainNavBar from '../components/MainNav';
import MainFooter from '../components/MainFooter';
import React,{useState,useEffect} from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, TextField, Typography } from '@material-ui/core';
import {  blackColor, primaryColor, whiteColor } from '../assets/contants';
import TestImage from "../assets/images/headermain.jpg"

import Header from '../components/header';

const useStyles = makeStyles((theme) => ({
  root:{
    flex:1,
    [theme.breakpoints.down('md')]: {
        overflowX:'hidden',
    },
  },
  headermain:{
    height:400,
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:400,
  },
  },
  header:{
    height:500,
    backgroundImage: `url(${TestImage})`,
    minHeight: 300,
    backgroundAttachment:'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    top:0,
    position:'absolute',
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:480,
  },
  },
  aboutustitle:{
      paddingTop:120,
      textAlign:'center',
      fontSize:50,
      color:whiteColor
  },
  aboutussubtitle:{
    textAlign:'center',
    fontSize:12,
    color:whiteColor
  },

  container:{
     overflowX:'hidden'
  },
  title:{
      fontSize:14,
      fontWeight:'600',
      color:'#555'
  },
  desc:{
    fontSize:12,
    color:'#555',
    padding:'5px 0px'
  },
  contactdiv:{
      marginBottom:20
  },
  contactpaper:{
      padding:'40px 20px',
      backgroundColor:'#eee'
  },
  visiontitle:{
    fontSize:20,
    fontWeight:500,
    letterSpacing:1
  },
  visionunderline:{
    padding:1,
    backgroundColor:primaryColor,
    width:'20%',
    borderRadius:2,
    margin:'10px 0px'
  },
  input:{
      width:'100%',
      padding:10,
      borderRadius:5,
      border:"0.8px solid",
      borderColor:'#cdcdcd',
      margin:'10px 0px',
      outline:primaryColor,
      "&:focus":{
        borderColor: primaryColor,
    }
  },
  submit:{
      padding:'5px',
      border:'1px solid #777',
      width:150,
      textAlign:'center',
      letterSpacing:1,
      cursor:'pointer',
      "&:hover":{
          backgroundColor:blackColor,
          color:whiteColor,
          transition:"transform 1s ease",
      },

  }
  
 
}));


function Contactus() {
  const classes = useStyles();
  let listener = null
  const [scrollState, setScrollState] = useState(false)

  useEffect(() => {
    listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      console.log(scrolled)
      if (scrolled >= 10) {
          setScrollState(true)
      } else {
        setScrollState(false)
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [])



  return (
    <div className={classes.root}>
        <MainNavBar scrolled={scrollState}/>
        <Header name="Contact us" />

        <Container className={classes.container}>
            <Grid container>
                <Grid item xs={12} sm={4} style={{marginTop:10}}>
                
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7221.01265123664!2d55.274329!3d25.186142!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa639fd1c28ec79ab!2sWestburry%20Residence!5e0!3m2!1sen!2sin!4v1630822412581!5m2!1sen!2sin" width="350" height="500" allowfullscreen="" loading="lazy"></iframe>
                  
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className={classes.contactdiv}>
                    <Typography className={classes.title}>Address:</Typography>
                    <Typography className={classes.desc}>#305 & #306, Westburry Towers, Business bay Dubai UAE</Typography>
                    </div>

                    <div className={classes.contactdiv}>
                    <Typography className={classes.title}>Phone:</Typography>
                    <Typography className={classes.desc}>+971 426 77657</Typography>
                    </div>
                    <div className={classes.contactdiv}>
                    <Typography className={classes.title}>Fax:</Typography>
                    <Typography className={classes.desc}>+971 426 77728</Typography>
                    </div>

                    <div className={classes.contactdiv}>
                    <Typography className={classes.title}>Po Box:</Typography>
                    <Typography className={classes.desc}>282194 Dubai</Typography>
                    </div>


                    {/* <div className={classes.contactdiv}>
                    <Typography className={classes.title}>Fax:</Typography>
                    <Typography className={classes.desc}>04 2677728</Typography>
                    </div> */}

                    <div className={classes.contactdiv}>
                    <Typography className={classes.title}>Email</Typography>
                    <Typography className={classes.desc}>info@syedcontracting.com</Typography>
                    <Typography className={classes.desc}> sales@syedcontracting.com</Typography>
                    <Typography className={classes.desc}>procurement@syedcontracting.com</Typography>
                    </div>

                  
                    <div className={classes.contactdiv}>
                    <Typography className={classes.title}>Office Timings</Typography>
                    <Typography className={classes.desc}>Sat - Thu 08:00 AM - 05:00 PM</Typography>
                    </div>
                    
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className={classes.contactpaper}>
                        <Typography className={classes.visiontitle} >Contact Us</Typography>
                        <div className={classes.visionunderline} ></div>
                        <input type="text" placeholder="Your Name" className={classes.input} />
                        <input type="text" placeholder="Your Email" className={classes.input} />
                        <input type="text" placeholder="Your Phone" className={classes.input} />
                        <textarea type="text" placeholder="Your Message" rows="5" className={classes.input} />

                        <Typography className={classes.submit}>
                            SUBMIT FORM
                        </Typography>
                    </div>
                </Grid>
            </Grid>

        </Container>
       
      

        <MainFooter />
    </div>
  );
}



export default Contactus;
