import './App.css';
import Homepage from './pages/Homepage';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import AboutUs from './pages/aboutus';
import Services from './pages/services';
import Contactus from './pages/contactus';
import Events from './pages/events';
import Projects from './pages/projects';
import EarthMoving from './pages/Services/earthmoving';
import PipeLaying from './pages/Services/pipelaying';
import BackFilling from './pages/Services/backfilling';
import Chamber from './pages/Services/chamber';
import EandD from './pages/Services/EandD';
import DCDept from './pages/Services/DCdept';
import Preventive from './pages/Services/preventivemaintain';
import MicroTunneling from './pages/Services/microtunneling';
import SignIn from './pages/Dashboard/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import AdminEvents from './pages/Dashboard/Events';
import AdminProjects from './pages/Dashboard/Projects';

function App() {
  return (
   
    <div className="App">
    <Router>
     
      <Route exact path="/" component={Homepage} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/aboutus" component={AboutUs} />
      <Route exact path="/contactus" component={Contactus} />
      <Route exact path="/events" component={Events} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/earthmove" component={EarthMoving} />
      <Route exact path="/pipelaying" component={PipeLaying} />
      <Route exact path="/backfilling" component={BackFilling} />
      <Route exact path="/chamber" component={Chamber} />
      <Route exact path="/EandD" component={EandD} />
      <Route exact path="/DCDepartment" component={DCDept} />
      <Route exact path="/preventive" component={Preventive} />
      <Route exact path="/microtunneling" component={MicroTunneling} />

      {/* Admin Dasboard */}
      <Route exact path="/admin" component={SignIn} />
      <Route exact path="/dashboard" component={Dashboard} />


     
      </Router>
    </div>
  );
}




export default App;
