import React from "react";
import {MDBCol, MDBContainer, MDBFooter, MDBRow} from "mdbreact";
import {blackColor, primaryColor,whiteColor} from "../assets/contants"
import "../App.css"
import Logo from "../assets/images/logo_light.png"
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import {Link} from 'react-router-dom'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';


const useStyles = makeStyles((theme) => ({
    logoimage:{
        width:'50%',
        height:60,
        objectFit:'contain'
    },
    listcontainer:{
        padding:"5px 0px",
        borderBottom:'1px solid #4a4a4a',
        display:'flex'
    },
    footertitle:{
        // paddingRight:20,
        color:primaryColor,
        fontWeight:700
    },
    hireuscontainer:{
        padding:"15px 30px",
        display:'flex',
        justifyContent:'space-between',
        backgroundColor:primaryColor,
        marginTop:20,
        [theme.breakpoints.down('md')]: {
         padding:"5px 10px",
     },
     
      },
      hiretitle:{
        padding:'15px',
        color:whiteColor,
        fontSize:20,
        [theme.breakpoints.down('md')]: {
         padding:'15px',
         width:'50%',
         fontSize:12
     },
      },
      hirebutton:{
       border:'2px solid white',
       padding:'8px 50px',
       marginTop:10,
       color:whiteColor,
       cursor:'pointer',
       backgroundPosition: 'right bottom',
       "&:hover":{
         background:'white',
         color: blackColor,
         border:'2px solid black',
         transition:'transform 250ms ease',
         color:whiteColor,
         background: "linear-gradient(to right, black 50%, transparent 50%)",
        backgroundSize: '200% 100%',
        backgroundPosition: 'left bottom',
        transition: "all .5s ease-out",
       },
       [theme.breakpoints.down('md')]: {
         padding:'5px 20px',
         textAlign:'center',
         fontSize:12,
         marginTop:"15%"
     },
    }

}));


function MainFooter(){
    const classes = useStyles()

    return(
        <>
         {/* <div className={classes.hireuscontainer}>
            <Typography variant="h5" className={classes.hiretitle}>Looking for best partner for your next construction works?</Typography>
            <div>
            <Link to={'/contactus'}><Typography className={classes.hirebutton}>Contact Us</Typography></Link>
            </div>
        </div> */}
        <MDBFooter  style={{backgroundColor:'#222222'}} className="page-footer font-small pt-4 ">
            <MDBContainer  className="text-center text-md-left">
                <MDBRow>
                    <MDBCol md="5">
                        <h5 className="text-uppercase mb-4 font-weight-bold">
                            <img src={Logo} alt="logo" className={classes.logoimage}/>
                        </h5>
                        <p style={{ textAlign:'justify'}}>
                        S Y E D Contracting “present team” has over 15 years of experience
                        in servicing the construction industry on a Design and Build, Engineering,
                        Procurement, Construction, and Commissioning of Water Works for the
                        Infrastructure activities in Dubai.                        </p>
                    </MDBCol>
                    <hr className="clearfix w-100 d-md-none" />
                    <hr className="clearfix w-100 d-md-none" />
                    <MDBCol md="7">
                        <h5 className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Contact Us
                        </h5>
                        <ul className="list-unstyled">
                            <li className={classes.listcontainer}>
                               <MailOutlineIcon style={{marginRight:10}}/>
                                <a href="#!"> info@syedcontracting.com</a>
                            </li>
                            <li className={classes.listcontainer}>
                                <PhoneIcon style={{marginRight:10}}/>
                                <a href="tel:+971 426 77657"> +971 426 77657</a>
                            </li>
                            <li className={classes.listcontainer}>
                                <a href="#!">
                                #305 & #306 West burry Towers, Marisi Drive Business Bay Dubai UAE.</a>
                            </li>
                        </ul>
                    </MDBCol>
                    <hr className="clearfix w-100 d-md-none" />
                
                </MDBRow>
            </MDBContainer>
            {/* <hr /> */}
            {/* <div className="text-center">
                <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                        <a className="btn-floating btn-sm btn-li mx-1" href={'#'}>
                            <i className="fab fa-linkedin-in"> </i>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a className="btn-floating btn-sm btn-dribbble mx-1" href={'#'}>
                            <i className="fab fa-dribbble"> </i>
                        </a>
                    </li>
                </ul>
            </div> */}
            <div className="footer-copyright text-center py-3">
                <MDBContainer fluid>
                    &copy; {new Date().getFullYear()} Copyright: Syed Contracting
                </MDBContainer>
            </div>
        </MDBFooter>
        </>
    )
}

export default MainFooter;