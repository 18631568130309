import MainNavBar from '../../components/MainNav';
import MainFooter from '../../components/MainFooter';
import React,{useState,useEffect} from "react"
import BGImage from "../../assets/images/service1.jpeg"
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import {  blackColor, primaryColor, whiteColor } from '../../assets/contants';
import TestImage from "../../assets/images/headermain.jpg"
import Header from '../../components/header';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme) => ({
  root:{
    flex:1,
    [theme.breakpoints.down('md')]: {
        overflowX:'hidden',
    },
  },
  headermain:{
    height:400,
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:400,
  },
  },
  header:{
    height:500,
    backgroundImage: `url(${TestImage})`,
    minHeight: 300,
    backgroundAttachment:'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    top:0,
    position:'absolute',
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:500,
  },
  },
  aboutustitle:{
      paddingTop:120,
      textAlign:'center',
      fontSize:50,
      color:whiteColor
  },
  aboutussubtitle:{
    textAlign:'center',
    fontSize:12,
    color:whiteColor
  },
  img:{
      width:'95%',
      height:200,
      objectFit:'cover',
      transition : "transform .4s ease",
      "&:hover":{
        transform: 'scale(1.1)',
        transformOrigin: '50% 50%',
        
      }
  },
  broucherbutton:{
    display:'flex',
    flexDirection:'row',
    backgroundColor:blackColor,
    justifyContent:'center',
    padding:'8px 20px',
    margin:'0% 10%',
    borderRadius:4,
    fontWeight:'400'

  },
  icon:{
    color:whiteColor,
    marginRight:10,
    fontSize:22
  },
  brouchertitle:{
    color:whiteColor,
    fontWeight:'600',
    letterSpacing:1,
    fontSize:16
  },
  description:{
    fontSize:16,
    color:'#777',
    margin:'20px 0px'
  },
  list:{
      fontSize:16,
      color:'#777',
  },
  image:{
    width:'100%',
    height:300,
    objectFit:'contain'
  }
 
}));


function PipeLaying() {
  const classes = useStyles();
  let listener = null
  const [scrollState, setScrollState] = useState(false)

  useEffect(() => {
    listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      console.log(scrolled)
      if (scrolled >= 10) {
          setScrollState(true)
      } else {
        setScrollState(false)
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [])



  return (
    <div className={classes.root}>
        <MainNavBar scrolled={scrollState}/>
        <Header name="Transmission Pipe Laying" />

        <Container className={classes.container}>
            <Grid container>
              <Grid item xs={12} sm={7}>
                  <Typography className={classes.description}>
                  We offer our services for laying, jointing, cleaning, swabbing, disinfection, testing, repairing, and commissioning of transmission pipelines in potable water, irrigation, sewage, and HVAC industry applications. No matter if your project is large or small, simple, or complicated, we can help you find the pipe laying solutions for the unique needs and challenges of your site.
                  </Typography>
                  <Typography className={classes.description}>
                  Over the years, S Y E D Contracting has built a reputation for reliability, safety, and quality in pipe laying services. With our extensive experience and commitment to quality, we strive to offer reliable, long-lasting, and cost-effective water utility installations. We take pride in our high-quality equipment and thorough and efficient service, which enables us to provide our clients with a solid foundation for future construction.
                  </Typography>
                  <Typography className={classes.description}>
                  Pipe laying is an important part of the initial groundwork for any construction project, and it can present several challenges and complications that must be addressed quickly and effectively.                  </Typography>
                  <Typography className={classes.description}>
                  As a result, it is critical that you have a properly trained and qualified pipe laying contractor you can rely on to install pipelines that are durable, properly fitted, consistently provide quality, and comply with all applicable rules, regulations, and requirements.
                 </Typography>

                  {/* <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <img src={BGImage} className={classes.image} />
                    </Grid>
                    <Grid item xs={6}>
                        <img src={BGImage} className={classes.image} />
                    </Grid>
                  </Grid> */}
              </Grid>
              <Grid item xs={12} sm={5}>
              <img src={BGImage} className={classes.image} />
              {/* <div className={classes.broucherbutton}>
                  <DescriptionIcon className={classes.icon} />
                  <Typography className={classes.brouchertitle}>BROUCHER</Typography>
              </div> */}
                </Grid>
            </Grid>
        </Container>

        <MainFooter />
    </div>
  );
}



export default PipeLaying;
