import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EventIcon from '@material-ui/icons/Event'
import { mainListItems, secondaryListItems } from './listItems';
import Chart from './Chart';
import PropTypes from "prop-types";
import Deposits from './Deposits';
import Orders from './Orders';
import { firestore } from '../../firebase';
import {useHistory} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ExpertOrders from "./ExpertOrders";
import TextField from "@material-ui/core/TextField";
import Title from "./Title";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import AddIcon from '@material-ui/icons/Add';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Logo from '../../assets/images/logo_icon.png';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
    Switch,
    Route,
    BrowserRouter,
    Link
  } from "react-router-dom";
import AdminProjects from './Projects';
import AdminEvents from './Events';
import { dashboardColor, primaryColor } from '../../assets/contants';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © Build and Decor Constructions '}
            {/*<Link color="inherit" href="https://kraneapps.com/">*/}
            {/*    KraneApps.com*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// Generate Order Data
function createData(state, name, phone, email,city, timestamp, pinCode) {
    return { state, name, phone, email,city, timestamp, pinCode };
}

const drawerWidth = 240;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 30,
        backgroundColor: dashboardColor// keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize:16
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

 function Dashboard() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [openList,setOpenList] = React.useState(true)
     const history = useHistory();

    useEffect(() => {
        firestore.collection('admin').doc('login').get().then(snapshot => {
            const currentUser = localStorage.getItem('xpertusername');
            if(currentUser == snapshot.data().username ){

            }else{
                history.push('/admin');
            }
        })
    })

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const handleListClick = () =>{
        setOpenList(!openList)
    }

    return (
        <BrowserRouter>
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Admin Dashboard
                    </Typography>
                    <IconButton color="inherit">
                        {/*<Badge badgeContent={4} color="secondary">*/}
                            <NotificationsIcon />
                        {/*</Badge>*/}
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <div>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            <img src={Logo} width={'40'}/>
                        </Typography>
                    </div>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <div>
                    <ListItem button className={classes.nested} component={Link} to={"/dashboard"}  className={classes.sidebarlink}>
                        <ListItemIcon>
                        <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Projects" />
                    </ListItem>
                    <ListItem button className={classes.nested} component={Link} to={"/adminevents"}  className={classes.sidebarlink}>
                        <ListItemIcon>
                        <EventIcon />
                        </ListItemIcon>
                        <ListItemText primary="Events" />
                    </ListItem>
                    </div>
                <div>
                
                </div>
                </List>

                <Divider />
                <ListItem button onClick={() => {
                    localStorage.removeItem('xpertusername');
                    history.push('/admin')
                }}>
                    <ListItemIcon>
                        <PowerSettingsNewIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>

            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Switch>
                <Route exact path="/dashboard" render={() => <div><AdminProjects /></div>} />
                <Route exact path="/adminevents" render={() => <div><AdminEvents /></div>} />
                </Switch>
            </main>
        </div>
        </BrowserRouter>
    );
}
Dashboard.propTypes = {
    container: PropTypes.instanceOf(
      typeof Element === "undefined" ? Object : Element
    )
  };

  export default (Dashboard)