import MainNavBar from '../components/MainNav';
import MainFooter from '../components/MainFooter';
import React,{useState,useEffect} from "react"
import BGImage from "../assets/images/headermain.jpg"
import Service1 from "../assets/images/service1.jpeg"
import Service2 from "../assets/images/service2.jpeg"
import Service3 from "../assets/images/service3.jpeg"
import Service4 from "../assets/images/service4.jpeg"
import Service5 from "../assets/images/service5.jpeg"
import Service6 from "../assets/images/service6.jpeg"
import Service7 from "../assets/images/home2.jpg"
import Service8 from "../assets/images/home3.jpg"
import Aos from "aos"
import 'aos/dist/aos.css';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import {  primaryColor, whiteColor } from '../assets/contants';
import TestImage from "../assets/images/headermain.jpg"
import Header from '../components/header';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root:{
    flex:1,
    [theme.breakpoints.down('md')]: {
        overflowX:'hidden',
    },
  },
  headermain:{
    height:'400px',
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:500,
  },
  },
  header:{
    height:500,
    backgroundImage: `url(${TestImage})`,
    minHeight: 300,
    backgroundAttachment:'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    top:0,
    position:'absolute',
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:500,
  },
  },
  aboutustitle:{
      paddingTop:120,
      textAlign:'center',
      fontSize:50,
      color:whiteColor
  },
  aboutussubtitle:{
    textAlign:'center',
    fontSize:12,
    color:whiteColor
  },
  img:{
      width:'95%',
      height:200,
      objectFit:'cover',
      transition : "transform .4s ease",
      "&:hover":{
        transform: 'scale(1.1)',
        transformOrigin: '50% 50%',
        
      }
  },
  container:{
      width:'100%'
  },
  title:{
      fontSize:20,
      padding:"10px 0px"
  },
  desc:{
      padding:'5px 0px',
      fontSize:14,
      color:'#777',
      fontWeight:'400',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign:'justify'
  },
  readmore:{
    padding:'10px 0px',
    fontSize:12,
    color:primaryColor,
    fontWeight:'600',
    textDecoration: 'underline'
  }
 
}));


function Services() {
  const classes = useStyles();
  let listener = null
  const [scrollState, setScrollState] = useState(false)
  const history = useHistory()

  useEffect(() => {
    Aos.init({duration:2000})
    listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      console.log(scrolled)
      if (scrolled >= 10) {
          setScrollState(true)
      } else {
        setScrollState(false)
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [])



  return (
    <div className={classes.root}>
        <MainNavBar scrolled={scrollState}/>
        <Header name="Services" />

        <Container className={classes.container}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4} style={{cursor:'pointer'}} onClick={()=>history.push("/earthmove")}  >
            <div class="item-zoom">
                <img src={Service2} alt="img" className={classes.img}/>
            </div>
                <Typography className={classes.title}>Enabling & Earthmoving Services</Typography>
                <Typography className={classes.desc}>S Y E D Contracting has established a solid reputation as a 
                trustworthy earthmoving contractor over the years. Traditional values such as hard work and dependability are 
                emphasized, while new technologies are used to innovate 
                and improve our services to provide the best results for a
                 better future.</Typography>
                <Typography className={classes.readmore}>read more >></Typography>
            </Grid>

            <Grid item xs={12} sm={4} style={{cursor:'pointer'}} onClick={()=>history.push("/pipelaying")}  >
            <div class="item-zoom">
                <img src={Service1} alt="img" className={classes.img}/>
            </div>
                <Typography className={classes.title}>Transmission Pipe Laying</Typography>
                <Typography className={classes.desc}>We offer our services for laying, jointing, cleaning, swabbing, disinfection, testing, repairing, and commissioning of transmission pipelines in potable water, irrigation...
</Typography>
                <Typography className={classes.readmore}>read more >></Typography>
            </Grid>

            <Grid item xs={12} sm={4} style={{cursor:'pointer'}} onClick={()=>history.push("/backfilling")}  >
            <div class="item-zoom">
                <img src={Service3} alt="img" className={classes.img}/>
            </div>
                <Typography className={classes.title}>Backfilling</Typography>
                <Typography className={classes.desc}>Backfilling is the process of reintroducing soil into a trench or foundation after the excavation and related work has been completed. Backfilling requires skills, heavy equipment...</Typography>
                <Typography className={classes.readmore}>read more >></Typography>
            </Grid>

            <Grid item xs={12} sm={4} style={{cursor:'pointer'}} onClick={()=>history.push("/chamber")}  >
            <div class="item-zoom">
                <img src={Service4} alt="img" className={classes.img}/>
            </div>
                <Typography className={classes.title}>Chamber Works</Typography>
                <Typography className={classes.desc}>The scope of our work includes design and construction of chambers for installation and commissioning of all types of valves, such as butterfly valve, isolation valve, air valves...</Typography>
                <Typography className={classes.readmore}>read more >></Typography>
            </Grid>

            <Grid item xs={12} sm={4} style={{cursor:'pointer'}} onClick={()=>history.push("/EandD")}  >
            <div class="item-zoom">
                <img src={Service5} alt="img" className={classes.img}/>
            </div>
                <Typography className={classes.title}>Design & Engineering</Typography>
                <Typography className={classes.desc}>Our in-house team of surveyors, designers, engineers, and project managers offer long-term, futuristic, environment friendly and sustainable infrastructure...</Typography>
                <Typography className={classes.readmore}>read more >></Typography>
            </Grid>

            <Grid item xs={12} sm={4} style={{cursor:'pointer'}} onClick={()=>history.push("/DCDepartment")}  >
            <div class="item-zoom">
                <img src={Service6} alt="img" className={classes.img}/>
            </div>
                <Typography className={classes.title}>District Cooling Department  </Typography>
                <Typography className={classes.desc}>Goal is to provide customer satisfaction through all their projects to the client’s needs and expectations. To achieve this, we do concentrate on quality, protection of environment...
                </Typography>
                <Typography className={classes.readmore}>read more >></Typography>
            </Grid>

            <Grid item xs={12} sm={4} style={{cursor:'pointer'}} onClick={()=>history.push("/microtunneling")}  >
            <div class="item-zoom">
                <img src={Service7} alt="img" className={classes.img}/>
            </div>
                <Typography className={classes.title}>Micro-Tunnelling</Typography>
                <Typography className={classes.desc}>Microtunneling is a trenchless construction method used to install water or other infrastructure pipelines, either deep lines or beneath highways, railroads, runways, harbours, rivers...</Typography>
                <Typography className={classes.readmore}>read more >></Typography>
            </Grid>

            <Grid item xs={12} sm={4} style={{cursor:'pointer'}} onClick={()=>history.push("/preventive")}  >
            <div class="item-zoom">
                <img src={Service8} alt="img" className={classes.img}/>
            </div>
                <Typography className={classes.title}>Preventive Maintenance</Typography>
                <Typography className={classes.desc}>Preventive maintenance (PM) is "a routine with the goal of "noticing small problems and fixing them before major ones develop. "
                </Typography>
                <Typography className={classes.readmore}>read more >></Typography>
            </Grid>
            
        </Grid>
        
           
        </Container>
       
      

        <MainFooter />
    </div>
  );
}



export default Services;
