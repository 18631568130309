import React,{useState,useEffect} from "react";
import {Nav, Navbar, Container, Dropdown, DropdownButton} from "react-bootstrap";
import { blackColor, primaryColor, whiteColor } from "../assets/contants";
import Logo from '../assets/images/logo_light.png'
import { useLocation, useHistory } from 'react-router-dom'
import "../App.css"
import { Typography } from "@material-ui/core";
import RoomIcon from '@material-ui/icons/Room';
import { makeStyles } from '@material-ui/core/styles';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const useStyles = makeStyles((theme) => ({
  navroot:{
    backgroundColor:blackColor,
    opacity:'0.7',
    zIndex:5,
    [theme.breakpoints.down('md')]: {
       display:'none'
    },
  },
  navrootdesktop:{
    [theme.breakpoints.down('md')]: {
      display:'none'
   },
  },
  icon:{
    color:primaryColor,
    marginRight:10,
    fontSize:18
  },
  navtitle:{
    fontSize:12,
    color:whiteColor
  },
  icon2:{
    color:whiteColor,
    fontSize:18
  },
  mobilenav:{
    backgroundColor:blackColor,
    display:'none',
    [theme.breakpoints.down('md')]: {
      display:'block'
   },
  }
}))

function MainNavBar(props){
  const location = useLocation()
  const [color,setColor] = useState()
  const classes = useStyles()

  useEffect(() => {
      console.log(location.pathname)
  }, [])




    return(
        <>

    <Navbar sticky="top"  expand="lg" className={classes.navroot}>
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" >
              <div style={{display:'flex'}}>
              <RoomIcon className={classes.icon}/>
              <Typography className={classes.navtitle} >Westburry Towers, Business bay Dubai UAE</Typography>
              </div>
            </Nav.Link>
            <Nav.Link href="#link">
            <div style={{display:'flex'}}>
              <QueryBuilderIcon className={classes.icon}/>
              <Typography className={classes.navtitle}>Sat - Thu 8:00am - 5:00pm</Typography>
              </div>
            </Nav.Link>
            <Nav.Link href="#link">
            <div style={{display:'flex'}}>
              <PhoneIcon className={classes.icon}/>
              <Typography className={classes.navtitle}><a style={{color:'#ffffff'}} href="tel:+97142677657">+971 426 77657</a></Typography>
              </div>
            </Nav.Link>
          </Nav>
          <Nav  className="ml-auto">
            {/* <Nav.Link><FacebookIcon className={classes.icon2}/></Nav.Link> */}
            <Nav.Link href="https://in.linkedin.com/company/syed-contracting-llc"><LinkedInIcon className={classes.icon2}/></Nav.Link>
            <Nav.Link href="mailto:info@syedcontracting.com"><MailOutlineIcon className={classes.icon2}/></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

  
  <Navbar collapseOnSelect expand="sm" sticky="top" 
  className={classes.navrootdesktop}
  style={{backgroundColor : props.scrolled === true ? blackColor : "transparent",boxShadow:'none'}}
  >
  <Container>
    <Navbar.Brand href="/" >
      <img
        src={Logo}
        width="200"
        height="45"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="ml-auto" activeKey={location.pathname} >
      <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link href="/services" >Services</Nav.Link>
      <Nav.Link href="/aboutus" >About Us</Nav.Link>
      <Nav.Link href="/projects" >Projects</Nav.Link>
      <Nav.Link href="/events" >Events</Nav.Link>
      <Nav.Link href="/contactus"  >Contact Us</Nav.Link>
    </Nav>
    </Navbar.Collapse>
  </Container>
  </Navbar>

      {/* MOBILE */}
  <Navbar collapseOnSelect expand="sm" fixed="top"  bg="dark" 
  className={classes.mobilenav}
  style={{backgroundColor : blackColor}}
  >
  <Container>
    <Navbar.Brand href="/" >
      <img
        src={Logo}
        width="200"
        height="45"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
      />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav"  bg="light" style={{backgroundColor:'#ffffff'}}/>
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="ml-auto" activeKey={location.pathname} >
      <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link href="/services" >Services</Nav.Link>
      <Nav.Link href="/aboutus" >About Us</Nav.Link>
      <Nav.Link href="/projects" >Projects</Nav.Link>
      <Nav.Link href="/events" >Events</Nav.Link>
      <Nav.Link href="/contactus"  >Contact Us</Nav.Link>
    </Nav>
    </Navbar.Collapse>
  </Container>
  </Navbar>
</>
    )
}

export default MainNavBar;