import MainNavBar from '../../components/MainNav';
import MainFooter from '../../components/MainFooter';
import React,{useState,useEffect} from "react"
import BGImage from "../../assets/images/service2.jpeg"
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import {  blackColor, primaryColor, whiteColor } from '../../assets/contants';
import TestImage from "../../assets/images/headermain.jpg"
import Header from '../../components/header';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme) => ({
  root:{
    flex:1,
    [theme.breakpoints.down('md')]: {
        overflowX:'hidden',
    },
  },
  headermain:{
    height:400,
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:400,
  },
  },
  header:{
    height:500,
    backgroundImage: `url(${TestImage})`,
    minHeight: 300,
    backgroundAttachment:'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    top:0,
    position:'absolute',
    width:'100%',
    [theme.breakpoints.down('md')]: {
      height:500,
  },
  },
  aboutustitle:{
      paddingTop:120,
      textAlign:'center',
      fontSize:50,
      color:whiteColor
  },
  aboutussubtitle:{
    textAlign:'center',
    fontSize:12,
    color:whiteColor
  },
  img:{
      width:'95%',
      height:200,
      objectFit:'cover',
      transition : "transform .4s ease",
      "&:hover":{
        transform: 'scale(1.1)',
        transformOrigin: '50% 50%',
        
      }
  },
  broucherbutton:{
    display:'flex',
    flexDirection:'row',
    backgroundColor:blackColor,
    justifyContent:'center',
    padding:'8px 20px',
    margin:'0% 10%',
    borderRadius:4,
    fontWeight:'400'

  },
  icon:{
    color:whiteColor,
    marginRight:10,
    fontSize:22
  },
  brouchertitle:{
    color:whiteColor,
    fontWeight:'600',
    letterSpacing:1,
    fontSize:16
  },
  description:{
    fontSize:16,
    color:'#777',
    margin:'20px 5px'
  },
  list:{
      fontSize:16,
      color:'#777',
  },
  image:{
    width:'100%',
    height:300
  }
 
}));


function EarthMoving() {
  const classes = useStyles();
  let listener = null
  const [scrollState, setScrollState] = useState(false)

  useEffect(() => {
    listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      console.log(scrolled)
      if (scrolled >= 10) {
          setScrollState(true)
      } else {
        setScrollState(false)
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [])



  return (
    <div className={classes.root}>
        <MainNavBar scrolled={scrollState}/>
        <Header name="Enabling & Earthmoving Services" />

        <Container className={classes.container}>
            <Grid container>
              <Grid item xs={12} sm={7}>
                  <Typography className={classes.description}>
                  S Y E D Contracting has established a solid reputation as a trustworthy earthmoving contractor over the years
                  </Typography>
                  <Typography className={classes.description}>
                  Traditional values such as hard work and dependability are emphasized, while new technologies are used to innovate and improve our services to provide the best results for a better future.
                  </Typography>
                  <Typography className={classes.description}>
                  We currently have a great team of professionals with years of grown experience and expertise. Our fantastic fleet of high-quality, modern earthmoving equipment includes excavators, backhoes, bulldozers, and bobcats. 
                  </Typography>
                  <Typography className={classes.description}>
                  This means we can adapt to a wide range of projects, tailoring our services to the unique needs and requirements of each job. Whatever your earthmoving needs are, we can help you complete the project efficiently, on time, and at a reasonable cost. s, attainable goals, and abundant promise.
                  </Typography>
                  <ul>
                        <li className={classes.list}> Surveying</li>
                        <li className={classes.list}>Excavations</li>
                        <li className={classes.list}>Waste Removal</li>
                        <li className={classes.list}> Initial site coursework</li>
                        <li className={classes.list}>Backfilling</li>
                        <li className={classes.list}>Compaction</li>
                        <li className={classes.list}>Final grading</li>
                      </ul>
              </Grid>
              <Grid item xs={12} sm={5}>
              <img src={BGImage} className={classes.image} />
                </Grid>
            </Grid>
        </Container>

        <MainFooter />
    </div>
  );
}



export default EarthMoving;
