import React,{useEffect} from 'react'
import { Button, Grid, Paper, Typography,TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { firestore } from '../../firebase';
import { storage } from '../../firebase';
import { dashboardColor, whiteColor } from '../../assets/contants';

const useStyles = makeStyles((theme) => ({
    title:{
        fontSize:24,
        color:dashboardColor,
        fontWeight:700,
        margin:20
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:16,
        width:110,
        background: dashboardColor,
        color:whiteColor,
        padding:3,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:"none"
    },
    root: {
        maxWidth: 345,
      },
      media: {
        height: 150,
      },
    content:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight: 200,
    },
      cardContainer:{
          padding:30
      },
      additempaper:{
          width:"96%",
          marginLeft:'2%',

          background:whiteColor,
          padding:20
      },
      textfeild:{
          width: '100%',
          marginBottom:10
      },
      uploadimage:{
          width:'80%',
          height:200
      },
      uploadimagescontainer:{
          display:'flex',
          justifyContent:'space-between',
          flexWrap:'wrap'
      },
      uploadedimage:{
          height:100,
          width:150,
          objectFit:'cover',
          marginBottom:10
      },
      addimage:{
          backgroundColor:dashboardColor,
          color:whiteColor,
          marginTop:10
      }

}))

export default function AdminProjects() {
    const classes = useStyles();
    const [addItem,setAddItem] = React.useState(false);
    const [title,setTitle] = React.useState("");
    const [spec,setSpec] = React.useState("");
    const [desc,setDesc] = React.useState("");
    const [uploadedImage1,setUploadedImage1] = React.useState("")
    const [uploadedImage2,setUploadedImage2] = React.useState("")
    const [uploadedImage3,setUploadedImage3] = React.useState("")
    const [uploadedImage4,setUploadedImage4] = React.useState("");
    const [uploadedImage5,setUploadedImage5] = React.useState("")
    const [uploadedImage6,setUploadedImage6] = React.useState("")
    const [uploadedImage7,setUploadedImage7] = React.useState("")
    const [uploadedImage8,setUploadedImage8] = React.useState("")
    const [uploadedImage9,setUploadedImage9] = React.useState("")
    const [uploadedImage10,setUploadedImage10] = React.useState("")

    const [cards,setCards] = React.useState([])


    useEffect(() => {
        getAllProducts();
    },[])

    const getAllProducts = () =>{
        firestore.collection('projects').get().then(snapshot => {
            const list = snapshot.docs.map(doc =>  {
                return doc.data()
            });
            setCards(list)
            console.log(list,"list")
        })
    }


    const handleClickUpload = () =>{
        setAddItem(!addItem)
    }

    const handleRefresh = () => {
        getAllProducts();
    }

    function uploadImage(files, type) {
        let file = files[0];
        const uploadTask = storage.ref(`/images/${file.name}`).put(file)
        uploadTask.on('state_changed',
            (snapShot) => {
            }, (err) => {
                console.log(err)
            }, () => {
                storage.ref('images').child(file.name).getDownloadURL().then(fireBaseUrl => {
                    // getting url here
                    if(type == 1){
                        setUploadedImage1(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                    }else if (type == 2){
                        setUploadedImage2(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                    }else if(type == 3){
                        setUploadedImage3(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                    }
                    else if(type == 4){
                        setUploadedImage4(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                    }
                    else if(type == 5){
                        setUploadedImage5(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                    }
                    else if(type == 6){
                        setUploadedImage6(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                    }
                    else if(type == 7){
                        setUploadedImage7(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                    }else if(type == 8){
                        setUploadedImage8(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                    }else if(type == 9){
                        setUploadedImage9(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                    }else if(type == 10){
                        setUploadedImage10(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                    }
                })
            })
    }


    const handleClickAdd = ()=>{
        setAddItem(!addItem)
        console.log('add item',title,desc)
        const timestamp = Date.now()
        const docRef = firestore.collection('projects').doc(timestamp.toString())
        docRef.set({
            title: title,
            description:desc,
            images: [uploadedImage1, uploadedImage2,uploadedImage3,uploadedImage4, uploadedImage5, uploadedImage6, uploadedImage7, uploadedImage8, uploadedImage9, uploadedImage10],
            timestamp: timestamp
        }, {merge: true})
        setTitle("")
        setSpec("")
        setDesc("")
        setUploadedImage1("")
        setUploadedImage2("")
        setUploadedImage3("")
        setUploadedImage4("")
        setUploadedImage5("")
        setUploadedImage6("")
        setUploadedImage7("")
        setUploadedImage8("")
        setUploadedImage9("")
        setUploadedImage10("")
        getAllProducts()
    }


    const handleDelete = (time) =>{
        console.log(time,"delete")
        firestore.collection('bedroom').doc(time.toString()).delete().then(r => console.log('item deleted'))
    }
    return (
        <div>
            <Grid container>
                <Grid item xs={6}>
                <Typography className={classes.title}>
                        Projects
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {  addItem ?
               <Button style={{backgroundColor: dashboardColor}} className={classes.addbutton} variant="contained" onClick={handleClickUpload}>Cancel</Button>
                :
                <Button style={{backgroundColor: dashboardColor}} className={classes.addbutton} variant="contained" onClick={handleClickUpload}>Add Item</Button>
            }
                    <Button style={{backgroundColor: dashboardColor}} className={classes.addbutton} variant="contained" onClick={() => handleRefresh()}>Refresh</Button>

                </Grid>
            </Grid>
            {
                addItem ?
                <div>
                    <Paper className={classes.additempaper}>

                    </Paper>
                </div>
                :
                null
            }

{
                addItem ?
                <div>
                    <Paper className={classes.additempaper}>
                        <div>
                        <TextField placeholder="Title" variant="outlined" className={classes.textfeild} onChange={(e)=>setTitle(e.target.value)}></TextField>
                        <TextField placeholder="Description" variant="outlined" className={classes.textfeild} rows={3} multiline={true} onChange={(e)=>setDesc(e.target.value)}></TextField>
                        </div>
                        <Grid container>
                            <Grid item xs={4}>
                              { uploadedImage1 != "" ?  <img src={uploadedImage1} className={classes.uploadimage}/> : null}
                                <Button
                                className={classes.addimage}
                                variant="contained"
                                component="label"
                                color={dashboardColor}
                                >
                                Upload image 1
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => uploadImage(e.target.files, 1)}
                                />
                            </Button>
                            </Grid>
                            <Grid item xs={4}>
                              { uploadedImage2 != "" ?  <img src={uploadedImage2} className={classes.uploadimage}/> : null}
                                <Button
                                className={classes.addimage}
                                variant="contained"
                                component="label"
                                color={dashboardColor}
                                >
                                Upload Image 2
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => uploadImage(e.target.files, 2)}
                                />
                            </Button>                            </Grid>
                            <Grid item xs={4}>
                              { uploadedImage3 != "" ?  <img src={uploadedImage3} className={classes.uploadimage}/> : null}
                                <Button
                                className={classes.addimage}
                                variant="contained"
                                component="label"
                                color={dashboardColor}
                                >
                                Upload Image 3
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => uploadImage(e.target.files, 3)}
                                />
                            </Button>
                            </Grid>
                            <Grid item xs={4}>
                              { uploadedImage1 != "" ?  <img src={uploadedImage4} className={classes.uploadimage}/> : null}
                                <Button
                                className={classes.addimage}
                                variant="contained"
                                component="label"
                                color={dashboardColor}
                                >
                                Upload image 4
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => uploadImage(e.target.files, 4)}
                                />
                            </Button>
                            </Grid>
                            <Grid item xs={4}>
                              { uploadedImage5 != "" ?  <img src={uploadedImage5} className={classes.uploadimage}/> : null}
                                <Button
                                className={classes.addimage}
                                variant="contained"
                                component="label"
                                color={dashboardColor}
                                >
                                Upload Image 5
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => uploadImage(e.target.files, 5)}
                                />
                            </Button>                            </Grid>
                            <Grid item xs={4}>
                              { uploadedImage6 != "" ?  <img src={uploadedImage6} className={classes.uploadimage}/> : null}
                                <Button
                                className={classes.addimage}
                                variant="contained"
                                component="label"
                                color={dashboardColor}
                                >
                                Upload Image 6
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => uploadImage(e.target.files, 6)}
                                />
                            </Button>
                            </Grid>
                            <Grid item xs={4}>
                              { uploadedImage7 != "" ?  <img src={uploadedImage7} className={classes.uploadimage}/> : null}
                                <Button
                                className={classes.addimage}
                                variant="contained"
                                component="label"
                                color={dashboardColor}
                                >
                                Upload image 7
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => uploadImage(e.target.files, 7)}
                                />
                            </Button>
                            </Grid>
                            <Grid item xs={4}>
                              { uploadedImage8 != "" ?  <img src={uploadedImage8} className={classes.uploadimage}/> : null}
                                <Button
                                className={classes.addimage}
                                variant="contained"
                                component="label"
                                color={dashboardColor}
                                >
                                Upload Image 8
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => uploadImage(e.target.files, 8)}
                                />
                            </Button>                            </Grid>
                            <Grid item xs={4}>
                              { uploadedImage9 != "" ?  <img src={uploadedImage9} className={classes.uploadimage}/> : null}
                                <Button
                                className={classes.addimage}
                                variant="contained"
                                component="label"
                                color={dashboardColor}
                                >
                                Upload Image 9
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => uploadImage(e.target.files, 9)}
                                />
                            </Button>
                            </Grid>
                            <Grid item xs={4}>
                              { uploadedImage10 != "" ?  <img src={uploadedImage10} className={classes.uploadimage}/> : null}
                                <Button
                                className={classes.addimage}
                                variant="contained"
                                component="label"
                                color={dashboardColor}
                                >
                                Upload Image 10
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => uploadImage(e.target.files, 10)}
                                />
                            </Button>
                            </Grid>
                        </Grid>
                       <div>
                       <Button style={{backgroundColor: dashboardColor,marginTop:20, color:'#ffffff'}} variant="contained" onClick={handleClickAdd}>Add Item</Button>
                       </div>
                    </Paper>
                </div>
                :
                null
            }
            <div className={classes.cardContainer}>


    <Grid container spacing={3}>
    {
    cards.map((item) => (
                <Grid item xs={4}>
                <Card className={classes.root}>
                    <CardActionArea>
                        <CardMedia
                        className={classes.media}
                     image={item.images[0]}
                        title="Contemplative Reptile"
                        />
                        <CardContent className={classes.content}>
                        <Typography gutterBottom variant="h6" component="h2">
                            {item.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                           {item.description}
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" className={classes.addbutton} onClick={()=>handleDelete(item.timestamp)} >
                        Delete
                        </Button>
                    </CardActions>
                </Card>
                </Grid>
    ))
    }
            </Grid>


            </div>
        </div>
    )
}